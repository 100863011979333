.contact-page{
    display: flex;
    align-content: center;
    justify-content: center;
}

.contact-mainpage {
    display: grid;
    align-content: center;
    justify-content: center;
    width: 1080px;
    z-index: 1;
}

.contact-mainpage .contact-message {
    font-weight: bold;
    font-size: 50px;
    margin: 50px 0px;
    display: grid;
    align-content: center;
    justify-content: center;
}

.contact-mainpage .contact-lower-message {
    font-size: 20px;
    margin: 50px 0px;
    display:grid;
    align-content: center;
    justify-content: center;
}

.contact-mainpage .contact-lower-message .contact-lower-message-link {
    color: black;
}


.contact-mainpage .contactForm label {
    margin: 20px 0px;
    font-size: large;
}

.contact-mainpage .contactForm .contact-grid-div .contact-message-area-count {
    margin-top: 10px;
    margin-bottom: -20px;
}

.contact-mainpage .contactForm .contact-name-div {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact-mainpage .contactForm .contact-name-div label {
    display: flex;
    flex-direction:column;
    margin: 0px 36px 10px 36px;
}

.contact-mainpage .contactForm .contact-name-div input {
    width: 350px;
    font-size: large;
    border: 1px solid black;
    border-radius: 5px;
}

.contact-mainpage .contactForm .contact-grid-div {
    align-content: center;
    display: grid;
    justify-content: center;
}

.contact-mainpage .contactForm .contact-grid-div input {
    width: 780px;
    font-size: large;
    border: 1px solid black;
    border-radius: 5px;
    display: grid;
}

.contact-mainpage .contactForm .contact-grid-div .contact-message-area {
    width: 780px;
    height: 250px;
    font-size: large;
    display: grid;
    font-family: Arial, sans-serif;
    resize: none;
    border: 1px solid black;
    border-radius: 5px;
}

.contact-mainpage .contactForm button {
  background-color: #046380;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 20px 0px;
  margin-left: 390px;
}

.contact-mainpage .contactForm button:hover {
    background-color: #035068;
    cursor: pointer;
  }


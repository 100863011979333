.loginPage {
    align-items: center;
    justify-content: center;
    display:grid;
}

.loginPage form .loginForm{
    margin: 10px 0px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.loginPage form .loginForm input{
    height: 22px;
    width: 200px;
    border: 1px solid black;
    border-radius: 22px;
    margin: 5px 0px;
}

.loginPage form .loginForm input:focus{
    border-radius: 5px;
}
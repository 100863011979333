
@media (min-width: 1199px) {
  .navbar-header {
    height: 100px;
    background-color: #046380;
    display:flex;
    justify-content: center;
    align-content: center;
  }
  
  .Header-column1 {
    width: 33.3vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-column3 {
    width: 33.3vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-Content {
    width: 1600px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100px;
  }
  
  .Header-Content .Logo {
    height: 90px;
    margin-top: 5px;
    margin-right: 50px;
    float: left;
  }
  
  .Header-Content .search-container {
    height: 90px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 330px;
    margin: 0 50px;
  }
  
  .Header-Content .search-container .search-icon-container {
    background-color: white;
    margin-top: 29px;
    width: 60px;
    height: 42px;
    display: inline;
    align-content: center;
    border-radius: 0 50px 50px 0px;
    
  }
  
  .Header-Content .search-container .search-icon-container:hover {
    cursor: pointer;
  }
  
  .Header-Content .search-container .search-icon-container .search {
    font-size:28px;
    margin-top: 6px;
    margin-left: 5px;
  }
  
  .search-container .search-input {
    border: none;
    height: 40px;
    margin-top: 29px;
    width: 300px;
    font-size: x-large;
  }
  
  .user-pages {
    background-color: transparent;
    height: 40px;
    margin-top: 30px;
    margin-left: 50px;
  }
  
  .user-pages .user-page-link {
    font-size: 30px;
    color: white;
    margin: 0 15px;
  }
  
  .hamburgermenu {
    display: none;
  }

  .hamburger-slide ul {
    display: none;
  }

  .hamburger-slide ul li{
    display: none;
  }

  .LowerHeader {
    display: none;

  }

  .LowerHeader .bottom-user-pages {
    display: none;
  }

  .LowerHeader .Logo {
    display: none;
  }

  .bottom-user-pages .user-page-link {
    display: none;
  }

  .bottom-hamburgermenu {
    display: none;
  }

  .bottom-hamburgermenu div {
    display: none;
  }

  .bottom-hamburgermenu .hidden {
    display: none;
  }

  .bottom-hamburgermenu .show {
    display: none;
  }

  .bottom-hamburger-slide {
    display: none;
  }
  .bottom-hamburger-slide ul {
    display: none;
  }

  .bottom-hamburger-slide ul li{
    display: none;
  }

  .hideBottomHamburgerMenu {
    display: none;
  }

  .showBottomHamburgerMenu {
    display: none;
  }
}

@media (max-width: 1198px) {
  .navbar-header {
    height: 100px;
    background-color: #046380;
    display:flex;
    justify-content: center;
    align-content: center;
  }
  
  .Header-column1 {
    width: 33.3vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-column3 {
    width: 33.3vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-Content {
    width: 1600px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100px;
  }
  
  .Header-Content .Logo {
    height: 90px;
    margin-top: 5px;
    margin-right: 50px;
  }
  
  .Header-Content .search-container {
    height: 90px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 330px;
    margin: 0 50px;
  }
  
  .Header-Content .search-container .search-icon-container {
    background-color: white;
    margin-top: 29px;
    width: 70px;
    height: 42px;
    display: inline;
    align-content: center;
    border-radius: 0 50px 50px 0px;
    
  }
  
  .Header-Content .search-container .search-icon-container:hover {
    cursor: pointer;
  }
  
  .Header-Content .search-container .search-icon-container .search {
    font-size:28px;
    margin-top: 6px;
    margin-left: 1px;
  }
  
  .search-container .search-input {
    border: none;
    height: 40px;
    margin-top: 29px;
    width: 300px;
    font-size: x-large;
  }
  
  .user-pages {
    display: none;
  }

  .hamburgermenu {
    display: block;
    display: grid;
    height: 25px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .hamburgermenu:hover {
    cursor: pointer;
  }

  .hamburgermenu div {
    background-color: white;
    width: 35px;
    height: 4px;
  }

  .hamburgermenu .rotateNeg {
    transform: rotate(-45deg);
    position: relative;
    bottom: 5px;
  }

  .hamburgermenu .rotatePos {
    transform: rotate(45deg);
    position: relative;
    top: 8px;
  }

  .hamburgermenu .hidden {
    display: none;
  }

  .hamburgermenu .show {
    display: block;
  }

  .hamburger-slide {
    position: absolute;
    display: none;
    height: 400px;
    width: 250px;
    z-index: 1;
    background-color: #035068;
    left: 64%;
    top: 100px;
    margin-right: 200px;
  }

  .hamburger-slide ul {
    list-style: none;
  }

  .hamburger-slide ul li {
    margin: 10px 0px;
    font-size: large;
  }

  .hamburger-slide .user-page-link-hamburger {
    color: white;
    text-decoration: none;
    height: 200px;
  }

  .hideHamburgerMenu {
    display: none;
  }

  .showHamburgerMenu {
    display: grid;
  }

  .LowerHeader {
    display: none;

  }

  .LowerHeader .bottom-user-pages {
    display: none;
  }

  .LowerHeader .Logo {
    display: none;
  }

  .bottom-user-pages .user-page-link {
    display: none;
  }

  .bottom-hamburgermenu {
    display: none;
  }

  .bottom-hamburgermenu div {
    display: none;
  }

  .bottom-hamburgermenu .hidden {
    display: none;
  }

  .bottom-hamburgermenu .show {
    display: none;
  }

  .bottom-hamburger-slide {
    display: none;
  }

  .hideBottomHamburgerMenu {
    display: none;
  }

  .showBottomHamburgerMenu {
    display: none;
  }

}

@media (max-width: 768px) {
  .navbar-header {
    height: 100px;
    background-color: #046380;
  }
  
  .Header-column1 {
    width: 0vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-column3 {
    width: 0vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-Content {
    width: 100vw;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100px;
  }
  
  .Header-Content .Logo {
    height: 90px;
    margin-top: 5px;
    margin-right: 50vw;
  }
  
  .Header-Content .search-container {
    height: 90px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100px;
    margin: 0 10px;
  }
  
  .Header-Content .search-container .search-icon-container {
    background-color: white;
    margin-top: 30px;
    width: 90px;
    height: 42px;
    display: inline;
    align-content: center;
    border-radius: 0 50px 50px 0px;
    
  }
  
  .Header-Content .search-container .search-icon-container:hover {
    cursor: pointer;
  }
  
  .Header-Content .search-container .search-icon-container .search {
    font-size: 22px;
    margin-left: 5px;
    margin-bottom: -2px;
  }
  
  .search-container .search-input {
    border: 0px;
    height: 40px;
    margin-top: 30px;
    margin-left: -45vw;
    width: 900px;
    font-size: large;
  }
  
  .user-pages {
    display: none;
  }

  .hamburgermenu {
    display: block;
    display: grid;
    height: 25px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .hamburgermenu:hover {
    cursor: pointer;
  }

  .hamburgermenu div {
    background-color: white;
    width: 35px;
    height: 4px;
  }

  .hamburgermenu .rotateNeg {
    transform: rotate(-45deg);
  }

  .hamburgermenu .rotatePos {
    transform: rotate(45deg);
  }

  .hamburgermenu .hidden {
    display: none;
  }

  .hamburgermenu .show {
    display: block;
  }

  .hamburger-slide {
    position: absolute;
    display: none;
    height: 400px;
    width: 250px;
    z-index: 1;
    background-color: #035068;
    left: 64%;
    top: 100px;
    margin-right: 200px;
  }

  .hideHamburgerMenu {
    display: none;
  }

  .showHamburgerMenu {
    display: grid;
  }

  .LowerHeader {
    display: none;

  }

  .LowerHeader .bottom-user-pages {
    display: none;
  }

  .LowerHeader .Logo {
    display: none;
  }

  .bottom-user-pages .user-page-link {
    display: none;
  }

  .bottom-hamburgermenu {
    display: none;
  }

  .bottom-hamburgermenu div {
    display: none;
  }

  .bottom-hamburgermenu .hidden {
    display: none;
  }

  .bottom-hamburgermenu .show {
    display: none;
  }

  .bottom-hamburger-slide {
    display: none;
  }

  .hideBottomHamburgerMenu {
    display: none;
  }

  .showBottomHamburgerMenu {
    display: none;
  }
}

@media (max-width: 451px) {
  .navbar-header {
    height: 100px;
    background-color: #046380;
  }
  
  .Header-column1 {
    width: 0vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-column3 {
    width: 0vw;
    background-color: transparent;
    height: 100px;
  }
  
  .Header-Content {
    width: 100vw;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100px;
  }
  
  .Header-Content .Logo {
    display: none;
  }
  
  .Header-Content .search-container {
    height: 90px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 400px;
    margin: 0 10px;
  }
  
  .Header-Content .search-container .search-icon-container {
    background-color: white;
    margin-top: 30px;
    width: 40px;
    height: 42px;
    display: inline;
    align-content: center;
    border-radius: 0 50px 50px 0px;
    
  }
  
  .Header-Content .search-container .search-icon-container:hover {
    cursor: pointer;
  }
  
  .Header-Content .search-container .search-icon-container .search {
    font-size: 22px;
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  
  .search-container .search-input {
    border: 0px;
    height: 40px;
    margin-left: 23px;
    width: 300px;
    font-size: large;
  }
  
  .user-pages {
    display: none;
  }

  .hamburgermenu {
    display: none;
    height: 25px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .LowerHeader {
    width: 100vw;
    height: 70px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#046380;
    bottom: 0;
  }

  .LowerHeader .bottom-user-pages {
    background-color: transparent;
    height: 40px;
    margin: 0 60px;
    display: flex;
  }

  .LowerHeader .Logo {
    height: 60px;
    float: left;
    display: block;
  }

  .bottom-user-pages .user-page-link {
    font-size: 30px;
    color: white;
    margin: 0 15px;
    display: block;
  }

  .bottom-hamburgermenu {
    display: grid;
    height: 25px;
  }

  .bottom-hamburgermenu:hover {
    cursor: pointer;
  }

  .bottom-hamburgermenu div {
    background-color: white;
    width: 35px;
    height: 4px;
    display: block;
  }

  .bottom-hamburgermenu .rotateNeg {
    transform: rotate(-45deg);
    position: relative;
    bottom: 5px;
    display: block;
  }

  .bottom-hamburgermenu .rotatePos {
    transform: rotate(45deg);
    position: relative;
    top: 7px;
    display: block;
  }

  .bottom-hamburgermenu .hidden {
    display: none;
  }

  .bottom-hamburgermenu .show {
    display: block;
  }

  .bottom-hamburger-slide {
    height: 400px;
    width: 200px;
    display: none;
    background-color:#035068;
    position: fixed;
    bottom: 70px;
    margin-left: 55%;
  }

  .hideBottomHamburgerMenu {
    display: none;
  }

  .showBottomHamburgerMenu {
    display: grid;
  }
}
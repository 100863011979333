
@media (min-width: 769px) {
    .Home-header {
        background-color: #f0f0f0;
        justify-content: center;
        align-items: center;
        display: flex;
        height: fit-content;
    }

    .Home-header .blocker {
        height: 20px;
        width: 850.13px;
        background-color: #f0f0f0;
    }

    .Home-header .Home-column1 {
        width: 0%;
    }

    .Home-header .Home-column3 {
        width: 0%;
    }

    .Home-header .Home-main {
        background-color: white;
        width: 850.13px;
        margin: 50px 0px;
    }

    .Home-header .Home-main .main-header {
        margin: 40px 0;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .Home-main .main-header .home-main-image {
        height: 200px;
        border-radius: 20px;
        background-color: black;
    }

    .Home-header .Home-main .main-header .home-main-image img {
        height: 100%;
        border-radius: 20px;
        width: 100%;
    }

    .Home-header .Home-main .home-main-image:hover {
        cursor: pointer;
    }

    .Home-header .Home-main .item-holder {
        display: flex;
        flex-wrap: wrap;
        margin: 50px 0px;
        justify-content: center;
        align-items: center;
        background-color: white;
    }

    .Home-header .Home-main .item-holder .item-link {
        padding: 10;
        border-radius: 12px;
        margin: 10px 20px;
        box-sizing: border-box;
        flex: 1 1 150px;
    }

    .Home-header .Home-main .item-holder img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }

    .Home-header .Home-main .item-holder img:hover {
        width: 105%;
        height: 105%;
        margin: -5% -5%;
        border-radius: 12px;
        border-top: 4px solid #046380;
        border-left: 4px solid #046380;
        cursor: pointer;
    }

    .Home-header .stores-topmessage {
        width: 100%;
        text-align: center;
        font-size: xx-large;
        margin: 20px 0px;
    }

    .Home-header .stores-holder {
        width: 100%;
        height: 150px;
        background-color: white;
        display: flex;
    }

    .Home-header .stores-holder .store-arrow-left {
        height: 100%;
        width: 40px;
        background-color: transparent;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .stores-holder .store-arrow-right {
        height: 100%;
        width: 40px;
        background-color: transparent;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .stores-holder .store-arrow:hover {
        cursor: pointer;
        background-color: rgb(223, 223, 223);
    }

    .Home-header .stores-holder .stores {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        border-width: 2px;
        border-style: ridge;
        border-color: gray;
        margin: 3px 0px;
        margin-left: 40px;
    }

    .Home-header .stores-holder .stores img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .Home-header .stores-holder .stores img:hover {
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .Home-header {
        justify-content: center;
        align-items: center;
        display: flex;
        height: fit-content;
    }

    .Home-header .Home-column1 {
        background-color: black;
        width: 0vw;
        height: 100%;
    }

    .Home-header .Home-column3 {
        background-color: aqua;
        width: 0vw;
        height: 100%;
    }

    .Home-header .Home-main {
        background-color: burlywood;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .Home-header .Home-main .main-header {
        margin: 20px 25%;
        border-radius: 10px;
        background-color: lightgray;
        height: 200px;
        width: 300px;
    }

    .Home-header .Home-main .item-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: antiquewhite;
    }

    .Home-header .Home-main .item-holder .item {
        padding: 10;
        width: 20%;
        height: 20%;
        border-radius: 12px;
        margin: 10px 20px;
        box-sizing: border-box;
        flex: 1 1 30%;
    }

    .Home-header .Home-main .item-holder img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }

    .Home-header .Home-main .item-holder img:hover {
        width: 105%;
        height: 105%;
        margin: -5% -5%;
        border-radius: 12px;
        border-top: 4px solid #046380;
        border-left: 4px solid #046380;
        cursor: pointer;
    }

    .Home-header .stores-holder {
        width: 100%;
        height: 150px;
        background-color: white;
        display: flex;
    }

    .Home-header .stores-holder .store-arrow-left {
        height: 100%;
        width: 40px;
        background-color: transparent;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .stores-holder .store-arrow-right {
        height: 100%;
        width: 40px;
        background-color: transparent;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .stores-holder .store-arrow:hover {
        cursor: pointer;
        background-color: rgb(223, 223, 223);
    }

    .Home-header .stores-holder .stores {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        border-width: 2px;
        border-style: ridge;
        border-color: gray;
        margin: 3px 0px;
        margin-left: 63px;
    }

    .Home-header .stores-holder .stores img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .Home-header .stores-holder .stores img:hover {
        cursor: pointer;
    }
}

@media (max-width: 451px) {
    .Home-header {
        background-color: #f0f0f0;
        justify-content: center;
        align-items: center;
        display: flex;
        height: fit-content;
    }

    .Home-header .blocker {
        background-color: #f0f0f0;
        width: 100%;
        height: 50px;
    }

    .Home-header .Home-column1 {
        width: 0%;
    }

    .Home-header .Home-column3 {
        width: 0%;
    }

    .Home-header .Home-main {
        background-color: white;
        width: 100vw;
    }

    .Home-header .Home-main .main-header {
        margin: 40px 0px;
        height: 200px;
        width: 350px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .Home-main .main-header .home-main-image {
        height: 200px;
        width: 600px;
        border-radius: 20px;
        background-color: black;
    }

    .Home-header .Home-main .main-header .home-main-image img {
        height: 100%;
        border-radius: 20px;
        width: 100%;
        background-color: black;
    }

    .Home-header .Home-main .home-main-image:hover {
        cursor: pointer;
    }

    .Home-header .Home-main .item-holder {
        display: flex;
        flex-wrap: wrap;
        margin: 50px 0px;
        justify-content: center;
        align-items: center;
        background-color: white;
    }

    .Home-header .Home-main .item-holder .item-link {
        padding: 10;
        border-radius: 12px;
        margin: 1px 10px;
        box-sizing: border-box;
        height: 24%;
        width: 24%;
        flex: 1 1 45%;
    }

    .Home-header .Home-main .item-holder .item {
        height: 100%;
        width: 100%;
    }

    .Home-header .Home-main .item-holder img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }

    .Home-header .Home-main .item-holder img:hover {
        width: 105%;
        height: 105%;
        margin: -5% -5%;
        border-radius: 12px;
        border-top: 4px solid #046380;
        border-left: 4px solid #046380;
        cursor: pointer;
    }

    .Home-header .stores-holder {
        width: 100%;
        height: 150px;
        background-color: white;
        display: flex;
    }

    .Home-header .stores-holder .store-arrow-left {
        height: 100%;
        width: 40px;
        background-color: transparent;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .stores-holder .store-arrow-right {
        height: 100%;
        width: 40px;
        background-color: transparent;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-header .stores-holder .store-arrow:hover {
        cursor: pointer;
        background-color: rgb(223, 223, 223);
    }

    .Home-header .stores-holder .stores {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border-width: 2px;
        border-style: ridge;
        border-color: gray;
        margin: 23px 1.5vw;
    }

    .Home-header .stores-holder .stores img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .Home-header .stores-holder .stores img:hover {
        cursor: pointer;
    }
}
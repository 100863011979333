.footer {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer div {
    width: 33.3vw;
    height: 250px;
    justify-content: center;
    align-items: center;
}

.Footer-column1 {
    align-content: center;
    text-align: center;
    display: grid;
}

.Footer-column2 {
    align-content: center;
    text-align: center;
    display: grid;
}

.Footer-column3 {
     align-content: center;
     text-align: center;
     display: grid;
}

.footer h3 {
    color: white;
}

.footer .footer-link {
    width: fit-content;
    text-decoration: none;
    color: white;
    margin: 5px 10px;
    font-style: normal;
}

@media (max-width: 768px) {
    .footer {
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .footer div {
        width: 33.3vw;
        height: 250px;
        justify-content: center;
        align-items: center;
    }
    
    .Footer-column1 {
        align-content: center;
        text-align: center;
        display: grid;
    }
    
    .Footer-column2 {
        align-content: center;
        text-align: center;
        display: grid;
    }
    
    .Footer-column3 {
         align-content: center;
         text-align: center;
         display: grid;
    }
    
    .footer h3 {
        color: white;
    }
    
    .footer .footer-link {
        width: fit-content;
        text-decoration: none;
        color: white;
        margin: 5px 10px;
        font-style: normal;
    }

    .footer .footer-link i{
        font-size: 13px;
    }
}
#passwordNoMatch {
    color: red;
    display: none;
}

.Register-page {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f0f0f0;
}

.Register-page .Register-div {
    background-color: white;
    width: 685.91px;
    margin: 20px 0px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 10px;
}

.dbErrorRegister {
    color: red;
    font-weight: bold;
    font-size: medium;
}

.Register-page .Register-div .register-form {
    width: 650px;
    margin: 20px 0px;
}

.Register-page .Register-div .register-form label {
    text-align: left;
    font-size: large;
    margin-bottom: 5px;
}

.Register-page .Register-div .register-form input {
    text-align: left;
    border: 1px solid black;
    border-radius: 30px;
    height: 30px;
    font-size: larger;
}

.Register-page .Register-div .register-form .register-dob {
    width: 100px;
}

.Register-page .Register-div .register-form input:focus {
    border-radius: 5px;
}

.Register-page .Register-div .register-form .formDivRegister {
    display: grid;
    text-align: center;
    margin: 15px 0px;
}

.Register-page .Register-div .register-form .formDivRegister .dob {
    display: flex;
    justify-content: center;
}

.Register-page .Register-div .register-form .formDivRegister .dob select {
    width: 220px;
    height: 32px;
    font-size: larger;
    border-radius: 0px;
    margin: 0px -1px;
    border: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.Register-page .Register-div .register-form .formDivRegister .dob #day {
    border-left: 1px solid black;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
}

.Register-page .Register-div .register-form .formDivRegister .dob #month {
    border-left: 1px solid black;
}

.Register-page .Register-div .register-form .formDivRegister .dob #Year {
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
}

.Register-page .Register-div .register-form .RegisterHalfInput {
    display: flex;
    margin: -10px 0px;
}

.Register-page .Register-div .register-form .RegisterHalfInput input {
    width: 300px;
    margin-right: 38px;
}

.Register-page .Register-div .register-form .register-button {
    background-color: #046380;
    border: none;
    height: 50px;
    width: 100px;
    font-size: large;
    border-radius: 10px;
    color: white;
}

.Register-page .Register-div .register-form .register-button:hover {
    background-color: #035068;
    cursor: pointer;
}

.Register-page .Register-div .register-form .checkBoxAndTerms {
    display: flex;

}

.Register-page .Register-div .register-form .termsText {
    text-align: left;
    font-size:15px;
    position: relative;
    bottom: -9px;
    left: 2px;
}

